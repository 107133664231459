import * as React from "react";
import styled from "@emotion/styled";
import { size, padding, margin, transparentize, border } from "polished";
import { useTheme } from "@emotion/react";
import { useBreakpoint } from "../../hooks/useBreakpoint";
import { ViewLarger } from "../../components/ViewLarger";

type Layout = "vertical" | "horizontal";
type PastEventEntryProps = {
  title?: string;
  description?: React.ReactNode;
  year?: string;
  image?: string;
  layout?: Layout;
  imagePosition?: string;
  center?: string;
  isLeftToRight?: boolean;
};

const Empty = styled.div({
  width: "100%",
  flexGrow: 1,
});

const Left = styled.div(({ theme }) => ({
  flexGrow: 1,
  width: "100%",
  display: "flex",
  justifyContent: "flex-end",
}));

const Right = styled.div(({ theme }) => ({
  flexGrow: 1,
  width: "100%",
  display: "flex",
  justifyContent: "flex-end",
  [theme.breakpoints.phone]: {
    paddingRight: 0,
  },
}));

export const Center = styled.div(({ theme }) => ({
  ...padding(0, 16, 0, 16),
  ...theme.typography.Subtitle,
  ...theme.typography.accent,
  color: theme.colors.red,
  textAlign: "center",
  display: "flex",
  alignItems: "center",
  flexDirection: "column",
  flexGrow: 0,
  flexShrink: 0,
  [theme.breakpoints.phone]: {
    ...theme.typography.Header,
    paddingLeft: 8,
  },
}));

const Circle = styled.div(({ theme }) => ({
  ...size(12),
  flexShrink: 0,
  backgroundColor: theme.colors.red,
  borderRadius: "50%",
}));

const Line = styled.div(({ theme }) => ({
  ...size("100%", 4),
  borderRadius: theme.borders.radius.xsmall,
  backgroundColor: theme.colors.gray100,
  marginTop: 8,
  minHeight: 32,
}));

export const EventTitle = styled.h2(({ theme }) => ({
  ...theme.typography.HeaderB,
  ...theme.typography.accent,
  margin: 0,
  color: theme.colors.red,
  ...border("bottom", 1, "solid", theme.colors.gray100),
  paddingBottom: 8,
  marginBottom: 8,
  [theme.breakpoints.phone]: {
    ...theme.typography.BodyB,
  },
}));

export const EventYear = styled.div(({ theme }) => ({
  ...theme.typography.CaptionB,
  color: theme.colors.black,
}));

const Card = styled.div<{ layout: Layout }>(({ theme, layout }) => ({
  ...padding(16, 20),
  ...margin(-8, 0, 12, 0),
  ...border(1, "solid", theme.colors.gray100),
  backgroundColor: theme.colors.gray,
  borderRadius: theme.borders.radius.default,
  maxWidth: 440,
  width: "100%",
  boxShadow: theme.shadows.cardShadow,
  display: "flex",
  flexDirection: layout === "vertical" ? "column" : "row",
  gap: 8,
  [theme.breakpoints.phone]: {
    color: theme.colors.gray300,
    backgroundColor: theme.colors.gray,
  },
}));

const ImageWrapper = styled.div<
  Pick<PastEventEntryProps, "image" | "imagePosition"> & { layout: Layout }
>(({ image, theme, imagePosition, layout }) => ({
  ...border(1, "solid", theme.colors.gray100),
  width: "100%",
  height: layout === "horizontal" ? "100%" : 200,
  display: "flex",
  alignItems: "flex-end",
  justifyContent: "flex-end",
  backgroundImage: `url("${image}")`,
  backgroundSize: "cover",
  backgroundPosition: imagePosition ?? "center",
  flexShrink: 0,
  flexBasis: layout === "horizontal" ? "50%" : undefined,
  [theme.breakpoints.phone]: {
    width: "100%",
    height: 150,
  },
}));

const ViewLargerWrapper = styled.div(({ theme }) => ({
  ...padding(0, 6, 4, 6),
  ...margin(0, 2, 2, 0),
  borderRadius: theme.borders.radius.small,
  background: transparentize(0.4, theme.colors.black),
}));

const Wrapper = styled.div({
  paddingBottom: 8,
  display: "flex",
});

export const Event: React.FC<PastEventEntryProps> = ({
  center,
  description,
  isLeftToRight,
  year,
  title,
  image,
  imagePosition,
  layout = "vertical",
}) => {
  const theme = useTheme();
  const isPhone = useBreakpoint(theme.breakpoints.phone);
  const content = React.useMemo(
    () => (
      <Card layout={isPhone ? "vertical" : layout}>
        {image && (
          <ImageWrapper
            image={image}
            imagePosition={imagePosition}
            layout={layout}
          >
            <ViewLargerWrapper>
              <ViewLarger src={image} variation="light" />
            </ViewLargerWrapper>
          </ImageWrapper>
        )}
        <div>
          <EventYear>{year}</EventYear>
          <EventTitle>{title}</EventTitle>
          {description}
        </div>
      </Card>
    ),
    [description, year, title, image, imagePosition, layout, isPhone]
  );

  if (center) {
    return (
      <Wrapper style={padding(12, 0)}>
        {!isPhone && <Empty>&nbsp;</Empty>}
        <Center>{center}</Center>
        <Empty>&nbsp;</Empty>
      </Wrapper>
    );
  }

  return isPhone ? (
    <Wrapper>
      <Center>
        <Circle />
        <Line />
      </Center>
      <Right>{content}</Right>
    </Wrapper>
  ) : (
    <Wrapper>
      {isLeftToRight ? <Left>{content}</Left> : <Empty>&nbsp;</Empty>}
      <Center>
        <Circle />
        <Line />
      </Center>
      {isLeftToRight ? <Empty>&nbsp;</Empty> : <Right>{content}</Right>}
    </Wrapper>
  );
};
