import * as React from "react";
import { Title } from "../../components/Typography";
import styled from "@emotion/styled";
import { Image } from "../../components/Image";
import { border } from "polished";
import { ViewLarger } from "../../components/ViewLarger";
import { notes } from "./content/notes";

const Letter = styled.div(({ theme }) => ({
  marginTop: 40,
  paddingTop: 40,
  display: "flex",
  justifyContent: "space-between",
  ...border("top", 2, "solid", theme.colors.gray100),
  [theme.breakpoints.phone]: {
    flexDirection: "column-reverse",
  },
}));

const Contents = styled.div(({ theme }) => ({
  paddingRight: 56,
  fontStyle: "italic",
  maxWidth: "60%",
  [theme.breakpoints.phone]: {
    maxWidth: "none",
    paddingRight: 0,
    paddingTop: 16,
  },
}));

const TitleWrapper = styled.div({
  paddingBottom: 20,
});

const Document = styled.div({
  maxWidth: "40%",
  width: "40%",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
});

export const NotesFromSeniors: React.FC = () => {
  return (
    <>
      <TitleWrapper>
        <Title>Notes from seniors</Title>
      </TitleWrapper>
      <p>
        Here are some of the lovely notes we have received from seniors over the
        years:
      </p>
      {notes.map(({ src, alt, contents }) => (
        <Letter key={alt}>
          <Contents>{contents}</Contents>
          <Document>
            <Image src={src} alt={alt} />
            <ViewLarger src={src} variation="highlighted" />
          </Document>
        </Letter>
      ))}
    </>
  );
};
