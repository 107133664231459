import * as React from "react";
import { Divider } from "../components/Divider";
import { Title } from "../components/Typography";
import { ActionBox } from "../components/ActionBox";
import { Button } from "../components/Button";
import talentShow2022 from "../assets/photos/talentShow2022-2.jpg";
import Friends5 from "../assets/photos/friends5.jpg";
import Friends from "../assets/photos/friends.jpg";
import { urls } from "../helpers/urls";
import { padding, transparentize } from "polished";
import styled from "@emotion/styled";
import Elf from "../assets/photos/elf.png";

/** Hero stock image found here
 * https://www.pexels.com/photo/joyful-adult-daughter-greeting-happy-surprised-senior-mother-in-garden-3768131
 * Photo by Andrea Piacquadio from Pexels
 * Licensing as of 10/24/2021 says:
 *  ✓ Free to use.
 *  ✓ No attribution required.
 */
import HeroImage from "../assets/hero.jpg";
import { useBreakpoint } from "../hooks/useBreakpoint";
import { useTheme } from "@emotion/react";
import { WeServe } from "../components/WeServe";
import { margin } from "polished";
import { SideBySide } from "../components/SideBySide";

const Accent = styled.span(({ theme }) => ({
  ...theme.typography.Subtitle,
  ...theme.typography.accent,
  color: theme.colors.red,
}));

const Actions = styled.div(({ theme }) => ({
  display: "flex",
  gap: 16,
  [theme.breakpoints.tabletPortrait]: {
    flexDirection: "column",
    padding: 0,
  },
}));

const Stats = styled.div(({ theme }) => ({
  display: "flex",
  flexWrap: "wrap",
  justifyContent: "center",
  ...padding(24, 0, 16, 0),
  gap: 40,
  [theme.breakpoints.tabletPortrait]: {
    flexDirection: "column",
    alignItems: "center",
  },
}));

const heroSizeDesktop = 500;
const heroSizeTablet = 400;
const heroSizeMobile = 300;

const navPaddingDesktop = 112;
const navPaddingTablet = 96;
const navPaddingMobile = 72;

const Wrapper = styled.div(({ theme }) => ({
  paddingTop: heroSizeDesktop - navPaddingDesktop,
  [theme.breakpoints.tabletLandscape]: {
    paddingTop: heroSizeTablet - navPaddingTablet,
  },
  [theme.breakpoints.phone]: {
    paddingTop: heroSizeMobile - navPaddingMobile,
  },
}));

const Hero = styled.div(({ theme }) => ({
  position: "absolute",
  top: 0,
  left: 0,
  backgroundImage: `url("${HeroImage}")`,
  backgroundSize: "cover",
  backgroundPosition: "center 30%",
  height: heroSizeDesktop,
  width: "100vw",
  display: "flex",
  justifyContent: "flex-end",
  alignItems: "center",
  [theme.breakpoints.tabletLandscape]: {
    height: heroSizeTablet,
  },
  [theme.breakpoints.phone]: {
    height: heroSizeMobile,
  },
}));

const TitleWrapper = styled.div(({ theme }) => ({
  color: theme.colors.black,
  width: "100%",
  textAlign: "center",
}));

const Header = styled.div(({ theme }) => ({
  ...theme.typography.HeaderB,
  ...theme.typography.accent,
}));

const Announcement = styled.div(({ theme }) => ({
  ...margin(24, 40, 0, 0),
  boxShadow: theme.shadows.cardShadow,
  padding: 20,
  borderRadius: theme.borders.radius.default,
  background: transparentize(0.2, theme.colors.gray),
  width: 500,
  [theme.breakpoints.phone]: {
    display: "none",
  },
}));

const AnnouncementTitle = styled.div(({ theme }) => ({
  ...theme.typography.BodyB,
  flexGrow: 1,
  width: "100%",
}));

const ButtonWrapper = styled.div({
  display: "flex",
  justifyContent: "flex-end",
});

const Stat = styled.div(({ theme }) => ({
  ...padding(0, 20),
  ...theme.typography.Header,
  color: theme.colors.aqua,
  textAlign: "center",
  maxWidth: 300,
  [theme.breakpoints.phone]: {
    ":last-child": {
      paddingBottom: 0,
    },
  },
}));

const StatNumber = styled.div(({ theme }) => ({
  ...theme.typography.Subtitle,
  color: theme.colors.black,
  lineHeight: "40px",
  paddingBottom: 12,
}));

const spotlightId = "section-fiftieth-anniversary";
const onClickAnnouncement = () => {
  document?.getElementById(spotlightId)?.scrollIntoView();
};

export const Home: React.FC = () => {
  const defaultTheme = useTheme();
  const isPhone = useBreakpoint(defaultTheme.breakpoints.phone);
  return (
    <>
      <Hero aria-label="Background image woman hugging senior">
        <Announcement>
          <AnnouncementTitle>
            Celebrating 50+ years of providing crucial services to seniors and
            people who care about them.
          </AnnouncementTitle>
          <p>
            The holiday spirit continues! Be part of our{" "}
            <b>Holiday Elfing Project</b> by donating, preparing goodie bags, or
            becoming an elf.
          </p>
          <ButtonWrapper>
            <Button variation="tertiary" onClick={onClickAnnouncement}>
              Learn more
            </Button>
          </ButtonWrapper>
        </Announcement>
      </Hero>
      <Wrapper>
        <TitleWrapper>
          <Title>Welcome to Visiting Neighbors!</Title>
          <Header>
            <i>&ldquo;Neighbors helping neighbors&rdquo; since 1972</i>
          </Header>
        </TitleWrapper>
        <p>
          With 50+ years of experience, Visiting Neighbors has been helping
          seniors in New York City live meaningful and active lives by matching
          them with one of our dedicated volunteers. Our programs and services
          help seniors maintain their independence by connecting them with
          someone who cares. Our goal is to provide a helping hand to our
          community's elderly, who may not have family or friends nearby.
        </p>
        <Stats>
          <Stat color={defaultTheme.colors.aqua}>
            <StatNumber>
              44,000+
              {isPhone ? " " : <br />}
              seniors
            </StatNumber>
            Assisted by Visiting Neighbors since 1972
          </Stat>
          <Stat color={defaultTheme.colors.red}>
            <StatNumber>
              11,000+
              {isPhone ? " " : <br />}
              neighbors
            </StatNumber>
            Served through Shop & Escort and Friendly Visiting
          </Stat>
          <Stat color={defaultTheme.colors.red}>
            <StatNumber>
              13,000+
              {isPhone ? " " : <br />}
              hours
            </StatNumber>
            Of telephone reassurance in 2021
          </Stat>
        </Stats>
        <WeServe />
        <Divider />
        <Actions>
          <ActionBox
            imgAlt="Steve with senior"
            imgSrc={Friends5}
            cta="Explore"
            url={urls.programs.main}
            title="Our programs"
          >
            Visiting Neighbors provides a lifeline, keeping seniors independent
            and connected to the community.
          </ActionBox>
          <ActionBox
            imgAlt="Performer at the talent show"
            imgSrc={talentShow2022}
            cta="Donate"
            url={urls.donate}
            title="Giving"
          >
            Every contribution goes towards helping seniors and supporting
            neighborhood programs.
          </ActionBox>
          <ActionBox
            imgAlt="Two friends together"
            imgSrc={Friends}
            cta="Volunteer"
            url={urls.volunteer.getInvolved}
            title="Get involved"
          >
            From shopping to playing cards, we have several opportunities to
            connect with seniors.
          </ActionBox>
        </Actions>
      </Wrapper>
      <Divider id={spotlightId} />
      <SideBySide
        imgSrc={Elf}
        imgAlt="Luncheon"
        imageMaxWidth={350}
        isTransparent
      >
        <Accent>The holiday spirit continues here…</Accent>
        <p>
          Visiting Neighbors was excited as our tradition of <i>Elfing</i>{" "}
          holiday goodie bags to our seniors begins, which began early December
          2024, will continue for another month to celebrate the New Year. Here
          are some of the ways:
        </p>
        <ul>
          <li>
            {" "}
            <b>Donations:</b> We’re always grateful for any small, <u>new</u>{" "}
            gift items such as gloves, socks, mini flash lights, key chains,
            stationery, magnifiers, moisturizer, lip balm, packet tissues, pens,
            art materials, lap throws, tee-shirts and tote bags to put into
            goodie bags.
          </li>
          <li>
            <b>Goodie bag preparation:</b> Come into the Visiting Neighbors
            office to wrap items, put them in bags, and get them ready for
            delivery.
          </li>
          <li>
            <b>
              Become an elf now or mark your calendars to join us next holiday
              season.
            </b>
          </li>
        </ul>
        <p>
          We appreciate anything you can do to make this New Year extra special
          for Visiting Neighbors' seniors.{" "}
          <b>
            If you would be interested in being an elf or have some items we can
            add to the care packages please give us a call.
          </b>
        </p>
      </SideBySide>
    </>
  );
};
