import * as React from "react";
import { padding, margin, border } from "polished";
import styled from "@emotion/styled";

const Wrapper = styled.div(({ theme }) => ({
  ...margin(24, 0),
  ...padding(20, 32),
  ...border(2, "solid", theme.colors.aqua),
  fontWeight: theme.fontWeights.bold,
  borderRadius: theme.borders.radius.small,
  backgroundColor: theme.colors.aqua50,
  flexGrow: 1,
  [theme.breakpoints.tabletPortrait]: {
    ...padding(24, 24),
    borderRadius: theme.borders.radius.small,
  },
}));

export const WeServe: React.FC = () => (
  <Wrapper>
    <b>
      We serve seniors who reside in Manhattan from South Ferry to 30th Street,
      river to river and seniors living in Forest Hills and Rego Park, Queens.
      Additionally, we receive calls from all over NYC and its surrounding
      vicinity.
    </b>
  </Wrapper>
);
