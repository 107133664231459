import * as React from "react";
import styled from "@emotion/styled";
import { Button } from "./Button";
import { padding, border, margin } from "polished";

type ImageProps = { src: string };

const Image = styled.div<ImageProps>(({ theme, src }) => ({
  ...border(1, "solid", theme.colors.gray100),
  borderBottom: 0,
  borderTopLeftRadius: theme.borders.radius.small,
  borderTopRightRadius: theme.borders.radius.small,
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  backgroundImage: `url("${src}")`,
  backgroundSize: "cover",
  height: 184,
}));

const ButtonWrapper = styled.div({
  paddingTop: 16,
});

const Content = styled.div(({ theme }) => ({
  ...border(1, "solid", theme.colors.gray100),
  ...padding(16, 24),
  borderTop: 0,
  borderBottomLeftRadius: theme.borders.radius.small,
  borderBottomRightRadius: theme.borders.radius.small,
  backgroundColor: theme.colors.gray,
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
  alignItems: "center",
  flexGrow: 1,
  textAlign: "center",
  width: "100%",
}));

const Wrapper = styled.div(({ theme }) => ({
  borderRadius: theme.borders.radius.small,
  alignSelf: "stretch",
  display: "flex",
  flexDirection: "column",
  flexBasis: "33%",
  ":first-of-type": {
    marginLeft: 0,
  },
  ":last-of-type": {
    marginRight: 0,
  },
  [theme.breakpoints.tabletPortrait]: {
    ...margin(0, 0, 12, 0),
    flexBasis: "100%",
  },
}));

const Title = styled.div(({ theme }) => ({
  ...theme.typography.HeaderB,
  ...theme.typography.accent,
  width: "100%",
  paddingBottom: 8,
  textAlign: "center",
}));

const Description = styled.div({
  maxWidth: "100%",
  textAlign: "left",
});

type ActionBoxProps = {
  cta: string;
  url: string;
  imgSrc: string;
  imgAlt: string;
  title: string;
};

export const ActionBox: React.FC<ActionBoxProps> = ({
  cta,
  url,
  imgSrc,
  imgAlt,
  children,
  title,
}) => (
  <Wrapper>
    <Image role="img" aria-label={imgAlt} src={imgSrc}></Image>
    <Content>
      <Title>{title}</Title>
      <Description>{children}</Description>
      <ButtonWrapper>
        <Button variation="tertiary" href={url}>
          {cta}
        </Button>
      </ButtonWrapper>
    </Content>
  </Wrapper>
);
