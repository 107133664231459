import * as React from "react";
import styled from "@emotion/styled";
import { Link } from "./Link";
import { Zoom } from "./Icons";
import { padding } from "polished";
import qs from "qs";
import { urls } from "../helpers/urls";

const Wrapper = styled.div({
  paddingTop: 4,
});

const Icon = styled.div({
  ...padding(0, 4, 4, 0),
  display: "inline-flex",
  alignItems: "center",
  verticalAlign: "middle",
});

type ViewLargerProps = {
  src: string;
  variation: "highlighted" | "light" | "dark";
};

export const ViewLarger: React.FC<ViewLargerProps> = ({ src, variation }) => (
  <Link
    href={`${urls.image}?${qs.stringify({
      src,
    })}`}
    openInNewWindow
    variation={variation}
  >
    <Wrapper>
      <Icon>
        <Zoom size="small" />
      </Icon>
      View larger
    </Wrapper>
  </Link>
);
